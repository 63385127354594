/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Paper, Tab, Tabs, } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgessBm, RBACAuth, SelectBm } from '../components/@material-extend';
import axiosApi from '../components/api/api';
import { LandRecordPath } from '../components/LandRecordPath';
import {
  AllCommentSubmit,
  Comments,
  farmerBankComments,
  farmerIdComments, farmerSigComments,
  Header,
  landRecordComments,
  ListDetails,
  Map,
  polygonComments,
  SizeOfPolygon,
  updateLog
} from '../layouts/fieldFarmerDetails';
import { AreaOfPolygon } from '../utils/AreaPolygon';
import { formatAllComments, getOldCommentsStr, getUniqueComments } from '../utils/common';

export const FieldsTables = () => {
  const [data, setData] = useState({});
  const [coor, setCoor] = useState([]);
  const groupID = "cropLandVerification";
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState("All Details");
  const [newCoor, setNewCoor] = useState([]);
  const urlQuery = new URLSearchParams(window.location.search);
  const [isVerificationReady, setIsVerificationReady] = useState(false);
  const { readonly } = useSelector((store) => store.userRoot.user); 
  const [allReworkComments, setAllReworkComments] = useState([]);
  const oldCommentsArr =
    data.internalcomments || data.farmerinternalcomments
      ? getOldCommentsStr(data.internalcomments, data.farmerinternalcomments)
          ?.trim()
          ?.split('.')
          .map((comment) =>
            comment !== 'null'
              ? {
                  comment,
                  status: ''
                }
              : null
          )
      : [];
        
  const [comments, setComments] = useState({
    polygon: [],
    farmerBank: [],
    farmerId: [],
    farmerSig: [],
    landRecord: []
  });
  const [editData, setEditData] = useState({});
  const onClickREP = () => {
    const row = data;
    const url = window.location.pathname;
    const newUrl = url.replace("field", "farmers");
    const queryUrl = `?name=${`${row.repName}`}&id=${row.repid}&pid=${urlQuery.get("pid")}&uid=${
      row.repuserid
    }`;
    window.open(newUrl + queryUrl, "_blank");
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const query = urlQuery.get('pid')
        ? `?id=${urlQuery.get('id')}&partnerId=${urlQuery.get('pid')}`
        : `?id=${urlQuery.get('id')}`;
      const { data } = await axiosApi.get(`/FieldDetails${query}`);
      console.log("data is logged:", data);
      setIsVerificationReady(true);
      setData(data);
      setAllReworkComments(
       await formatAllComments(data?.farm_rework_comments, data?.farmer_rework_comments)
      );
      const { coordinates } = JSON.parse(data.coordinates);
      const newCoor = coordinates[0][0].map((ele) => [ele[1], ele[0]]);
      setCoor([newCoor]);
    } catch (error) {
      console.log(error, "failed ");
      alert("failed");
    } finally {
      setLoading(false);
    }
  };

  const getNextFieldByStatus = async (status) => {
    try {
      setLoading(true);
      const partnerID = urlQuery.get("pid");
      const newStatus = status.replace("FIND ", "");
      const queryUrl = `?status=${newStatus}`;
      const { data } = await axiosApi.get(`findRandomFarmByStatus${queryUrl}`);
      if (!data.length) {
        throw new Error("No data available");
      }
      const url = window.location.pathname;
      const queryUrl2 = `?id=${data[0].id}&pid=${partnerID}`;
      window.open(url + queryUrl2, "_blank");
    } catch (error) {
      console.log(error);
      alert("Not avalible");
    } finally {
      setLoading(false);
    }
  };

  const onSave = async () => {
    try {
      setLoading(true);
      if (Object.prototype.hasOwnProperty.call(editData, "idNumber") && !editData.idNumber) {
        throw Error("Please enter ID number");
      }

      const coordinate = {
        type: "MultiPolygon",
        coordinates: [newCoor]
      };
      await axiosApi.post(`/updatePolygonAndFarmer`, {
        ...editData,
        coordinate,
        farmId: data.id
      });
      let coorEdit = false;
      if (newCoor[0]) {
        const swapCoor = newCoor[0].map((ele) => [ele[1], ele[0]]);
        setCoor([swapCoor]);
        coorEdit = true;
      }
      updateLog(data, "EDITED", "", coorEdit, editData);
    } catch (error) {
      console.log(error);
      alert(error.message ?? "failed");
    } finally {
      setLoading(false);
    }
  };

  const farmerDetails = (data) => {
    if (data.farmerCountry === "IN") {
      return {
        "Farmer Name": data.famerName,
        "Name on Bank Account": data.bankAccountHolderName,
        "Bank Type": data.accountType,
        "Bank account Number": data.bankAccount,
        "Bank Acount Code": data.bankcode,
        Country: data.farmerCountry,
        State: data.farmerState,
        // District: data.farmer_user_district,
        "Town/Village": data.farmerTown,
        Address: data.farmerAddress,
        "Postal Code": data.farmerpostalcode,
        Mobile: data.farmerphonenumber,
        Status: data.farmerStatus
      };
    }

    return {
      Country: data.farmerCountry,
      "Sub-county": data.farmerAddress2,
      // County: data.farmer_user_district,
      "Ward/Village": data.farmerTown,
      Address: data.farmerAddress,
      "Postal Code": data.farmerpostalcode,
      Mobile: data.farmerphonenumber,
      Status: data.farmerStatus
    };
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  if (loading) {
    return (
      <CircularProgessBm
        sx={{
          height: "100vh"
        }}
      />
    );
  }

  const renderBody = {
    'All Details': (
      <Grid item xs={9}>
        <Map
          coor={[coor]}
          setEditCoor={(v) => {
            setNewCoor(v);
          }}
          isVerificationReady={isVerificationReady}
          points={data.status === 'CONFIRMED' ? data.farmer_user_partner_comment : ''}
          comments={comments.polygon}
          setComments={(value) => setComments({ ...comments, polygon: value })}
          commentList={Array.from(polygonComments)}
        />
        <ListDetails
          isVerificationReady={isVerificationReady}
          comments={comments.farmerBank}
          setComments={(value) => setComments({ ...comments, farmerBank: value })}
          header="Farmer and Bank Details"
          commentList={Array.from(farmerBankComments)}
          keyValue={farmerDetails(data)}
        />
        <LandRecordPath
          isVerificationReady={isVerificationReady}
          comments={comments.farmerId}
          setComments={(value) => setComments({ ...comments, farmerId: value })}
          commentList={Array.from(farmerIdComments)}
          header="Farmer ID Proof"
          keyValue={{
            idType: editData.idType || data.idType,
            idNumber: Object.prototype.hasOwnProperty.call(editData, 'idNumber')
              ? editData.idNumber
              : data.idnumber
          }}
          onChange={(key, value) => {
            const data = { ...editData };
            data[key] = value;
            setEditData({ ...data });
          }}
          path={data.idphotopath}
          dis="|"
        />
        <LandRecordPath
          isVerificationReady={isVerificationReady}
          comments={comments.farmerSig}
          setComments={(value) => setComments({ ...comments, farmerSig: value })}
          commentList={Array.from(farmerSigComments)}
          header="Farmer Signature"
          path={data.signaturephotopath}
        />
        <LandRecordPath
          isVerificationReady={isVerificationReady}
          header="Land Record"
          comments={comments.landRecord}
          setComments={(value) => setComments({ ...comments, landRecord: value })}
          commentList={Array.from(landRecordComments)}
          path={data.landrecordphotopath}
          dis="|"
        />
        {data && data.recent_practice_improvements ? (
          <ListDetails
            header="Questionnaire"
            keyValue={{
              'When did you start your improved practices?': data.practiceStartDate,
              'Improved Practices': data.recent_practice_improvements,
              'Crops before': data.crops_before,
              'Crops after': data.crops_after,
              "Did you till/plow before 'practice date'?": data.tillage_flag,
              'How often did you till every year?': data.tillage_frequency,
              'How deep did you till, in centimeters?': data.tillage_depth,
              "Did you use manure before 'practice date'?": data.manure_flag,
              'What approximate amount of manure, in KG did you apply yearly?': data.manure_rate,
              "Did you use compost before 'practice date'?": data.compost_flag,
              "Did you graze animals on your land before 'practice date'?": data.grazing_flag,
              Animals: data.animals,
              'Total animal count': data.animal_counts,
              "Did you use irrigation before 'practice date'?": data.irrigation_flag,
              'What type of irrigation do you use?': data.irrigation_type,
              'How many times, in a year, do you water the farm?': data.irrigation_rate
            }}
          />
        ) : (
          <>
            <ListDetails
              header="Current Crop Details"
              keyValue={{
                'Select current crop group': data?.practicesAfter?.crop_group,
                'What is the current crop grown?': data?.practicesAfter?.crop,
                'Did you plough your field for current crop?': data?.practicesAfter?.plough_flag,
                'Enter the depth of ploughing (in cm)': data?.practicesAfter?.plough_depth,
                'Have you used manure for the current crop?': data?.practicesAfter?.manure_flag,
                'Mention the quantity in Kg': data?.practicesAfter?.manure_qty,
                'Select the type of fertiliser used in the field':
                  data?.practicesAfter?.fertilizer_type,
                Urea: data?.practicesAfter?.synthetic_fertilizer_qty,
                DAP: data?.practicesAfter?.dap_Rate,
                'Is your field irrigated for current crop?': data?.practicesAfter?.irrigation_flag
              }}
            />
            <ListDetails
              header="Previous Crop Details"
              keyValue={{
                'Select previous crop group': data?.practicesBefore?.crop_group,
                'What is the previous crop grown?': data?.practicesBefore?.crop,
                'Did you plough your field for previous crop?': data?.practicesBefore?.plough_flag,
                'Enter the depth of ploughing (in cm)': data?.practicesBefore?.plough_depth,
                'Have you used manure for the previous crop?': data?.practicesBefore?.manure_flag,
                'Mention the quantity in Kg': data?.practicesBefore?.manure_qty,
                'Select the type of fertiliser used in the field':
                  data?.practicesBefore?.fertilizer_type,
                Urea: data?.practicesBefore?.synthetic_fertilizer_qty,
                DAP: data?.practicesBefore?.dap_Rate,
                'Is your field irrigated for previous crop?':
                  data?.practicesBefore?.irrigation_flag,
                'What is the Yield / Output per acre for your previous crop (Kg/Acre)?':
                  data?.practicesBefore?.yield
              }}
            />
            <ListDetails
              header="Residue Management"
              keyValue={{
                'Do you allow livestock to graze in your field?':
                  data?.residueManagement?.grazing_flag,
                'How often do they graze?': data?.residueManagement?.grazing_Frequency,
                'Cows Count': data?.residueManagement?.cows_count,
                'Buffalo Count': data?.residueManagement?.buffalo_count,
                'Sheep Count': data?.residueManagement?.sheep_count,
                Others: data?.residueManagement?.animal_count
              }}
            />
          </>
        )}
      </Grid>
    ),
    'Boomitra Comments': (allReworkComments.length || oldCommentsArr.length) && (
      <Grid item xs={9}>
        <Comments dataArray={getUniqueComments(allReworkComments, oldCommentsArr)} />
      </Grid>
    )
  };

  return (
    <>
      {!loading && (
        <Grid
          spacing={1}
          container
          sx={{
            margin: "2rem auto",
            marginTop: "1rem",
            width: "85vw"
          }}
        >
          <Grid item xs={12}>
            <Header data={data} name={`${data.famerName}`} status={data.status} />
            <Paper elevation={10} sx={{ width: "100%", bgcolor: "#F4F6F8", display: "flex" }}>
              <Button variant="text" onClick={onClickREP}>
                REP ({data.repName}) ({data.repPhoneNumber})
              </Button>
              <SelectBm
                label="NEXT"
                containerSx={{
                  minWidth: "8rem"
                }}
                onChange={({ target }) => getNextFieldByStatus(target.value)}
                list={[
                  "FIND RECEIVED",
                  "FIND RE_SUB",
                  "FIND REWORK",
                  "FIND ONBOARDED",
                  "FIND VERIFIED"
                ]}
              />
              <div style={{ flex: 1 }} />
              <Tabs
                value={tabs}
                onChange={(_, v) => {
                  setTabs(v);
                }}
              >
                {["All Details", "Boomitra Comments"].map((ele) => (
                  <Tab label={ele} key={ele} value={ele} />
                ))}
              </Tabs>
              {isVerificationReady && (
                <Button variant="outlined" onClick={readonly ? null : onSave} disabled={readonly}>
                  SAVE
                </Button>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} container spacing={0.5}>
            <Grid item container xs={3} maxHeight="60vh">
              <Grid item xs={12}>
                <SizeOfPolygon
                  calculatedAcres={AreaOfPolygon([coor], !true)}
                  reportedAcres={data.landArea}
                />
              </Grid>
              <Grid item xs={12}>
                <ListDetails
                  header="Partner Details"
                  keyValue={{
                    Project: data.projectName,
                    Partner: data.partnerName,
                    Rep: data.repName,
                    FarmID: data.id,
                    FarmerID: data.farmerId,
                    REPID: data.repid
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ListDetails
                  keyValue={{
                    "Onboarded on": new Date(data.createdAt).toDateString().slice(4),
                    "Status Updated on": new Date(data.updatedAt).toDateString().slice(4),
                    "Updated by": data.updatedBy
                  }}
                />
              </Grid>
              {isVerificationReady ? (
                <RBACAuth groupID={groupID}>
                  <Grid item xs={12}>
                    <AllCommentSubmit
                      editCoor={newCoor}
                      comments={comments}
                      setComments={setComments}
                      data={data}
                      setData={setData}
                    />
                  </Grid>
                </RBACAuth>
              ) : null}
            </Grid>
            {renderBody[tabs]}
          </Grid>
        </Grid>
      )}
    </>
  );
};
