/* eslint-disable no-restricted-syntax */
/* eslint-disable no-const-assign */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-unresolved */
/* eslint-disable arrow-body-style */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Tabs, Tab, Box, Typography, Button, Stack, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SelectBm, LoadingBtnBm, AutocompleteBm } from 'src/components/@material-extend';
import { useSnakBarTran } from 'src/hook';
import { QuickSearchToolbar } from '../components/QuickSearch';
import { repAction } from '../redux/actions/repAction';
import { getContratorColumn, SearchRepKeys } from '../components/GridColumns';
import { ModalBM } from '../components/ModalBM';
import { ConfirmModal } from '../components/details/ConfirmModal';
import api from '../components/api/api';
import './table.css';




let allReps = [];
const mapping = {
  ALL: 'ALL',
  CONFIRMED: 'CONFIRMED',
  UNCONFIRMED: 'UNCONFIRMED',
  REWORK: 'REWORK',
  "READ_ONLY": "READ_ONLY"
}


const color = {
  UNCONFIRMED: 'rgba(247, 181, 38, 1)',
  CONFIRMED: 'rgba(135, 196, 94, 1)',
  'READ_ONLY': 'rgba(145, 158, 171, 0.16)',
  'Rework Only': 'rgba(255, 86, 48, 0.16)',
  'Not Verified': 'rgba(255, 86, 48, 0.16)',
  Verified: 'rgba(135, 196, 94, 0.3)',
  REWORK: 'rgba(61, 176, 222, 1)',
  ALL: 'rgba(4, 39, 41, 1)'
};
export const ContratorTables = () => {
  const history = useNavigate();
  const store = useSelector((store) => store);
  const { user, isAuthenticated, activeLeader } = store.userRoot;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [rep, setRep] = useState({});
  const [searchText, setSearchText] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [tabs, setTabs] = useState('ALL');
  const [selectStatus, setSelectStatus] = useState('CONFIRMED')
  const [fid, setFid] = useState()
  const [phoneNumber, setPhoneNumber] = useState()
  const [selectedRow, SetSelectedRow] = useState([])
  const { partnerID, readonly } = user;
  const [statusChangeLoading, setStatusChangeLoading] = useState(false)
  const group = "REPSTATUS"
  const [leaderIDs, setLeaderIDs] = useState([]);
  const [leaderIdWhiteList, setLeaderIdWhiteList] = useState("");
  const [monitneringPeriods, setMonitneringPeriods] = useState([])
  const [moniteringPeriodsData, setMoniteringPeriodsData] = useState([])
  const { showSuccessSnackbar, showErrorSnackbar } = useSnakBarTran();
  const findField = () => {
    const url = window.location.href;
    const newUrl = url.replace('REP', 'field/')
    const queryUrl = `?id=${fid}`
    window.open(newUrl + queryUrl, '_blank')
  }
  const findUser = async () => {
    try {
      let queryPhoneNumber = phoneNumber
      if (phoneNumber) {
        queryPhoneNumber = phoneNumber.replace("+", "%2B")
      }
      const queryUrl = `?phoneNumber=${queryPhoneNumber}&partnerId=${partnerID}`
      const { data } = await api.get(`findByPhoneNumber${queryUrl}`)

      if (data.type === "REP") {
        onClickRow(data);
      } else {
        onFarmer(data)
      }
    } catch (error) {
      console.log(error);
      alert("Not active User")
    }
  }

  const getMoniteringIDS = async () => {
    try {
      const { data } = await api.get(`getAllMoniteringPeriodByPartnerID?partnerID=${partnerID}`)
      setMoniteringPeriodsData(data)
    } catch (error) {
      showErrorSnackbar({ 'text': 'Not able to fetch Monitoring period' })
    }

  }

  const onFarmer = (row) => {
    const url = window.location.href;
    const newUrl = url.replace('REP', 'farmerAllField')
    const queryUrl = `/?name=${row.name}&id=${row.id}&pid=${partnerID}&uid=${row.userID}`
    window.open(newUrl + queryUrl, '_blank')
  }

  const onClickRow = (row) => {
    const url = window.location.href;
    const newUrl = url.replace('REP', 'farmers')
    const queryUrl = `/?name=${`${row.given_name} ${row.family_name}`}&id=${row.id}&pid=${partnerID}&uid=${row.id}`
    window.open(newUrl + queryUrl, '_blank')
  }

  const updateRow = (repIDs, status) => {
    const repIDSet = new Set(repIDs)
    const newRep = { ...rep };
    newRep.repData = [...rep.repData]
    for (let i = 0; i < newRep.repData.length; i++) {
      const { id } = newRep.repData[i]
      if (repIDSet.has(id)) {
        newRep.repData[i].status = status;
      }

    }
    setRep({ ...newRep });
  }

  const getREPIDLIST = (userIDs) => {
    const repIDSet = new Set(userIDs)
    const repIDs = [];
    const newRep = { ...rep };
    newRep.repData = [...rep.repData]
    for (let i = 0; i < newRep.repData.length; i++) {
      const { id, repId } = newRep.repData[i]
      if (repIDSet.has(id)) {
        repIDs.push(repId)
      }
    }
    return repIDs;
  }

  const changeStatusToUnConfirm = async () => {
    try {
      setStatusChangeLoading(true)
      const ids = getREPIDLIST(selectedRow);
      await api.post("/changeRepStatus", {
        repIDs: selectedRow,
        status: mapping[selectStatus],
        partnerID,
        ids,
        partnerDivisionID: leaderIdWhiteList,
        moniteringIDs: monitneringPeriods
      })
      showSuccessSnackbar({ text: "Successfully updated" })
      updateRow(selectedRow, selectStatus)
      SetSelectedRow([])
    } catch (e) {
      console.log(e);
      alert("Failed")
    } finally {
      setStatusChangeLoading(false)
    }
  }

  useEffect(() => {
    const { rep } = store.allRep;
    if (isAuthenticated && !rep.repData) {
      setRep({});
      setLoading(true);
      dispatch(repAction({
        partnerID: user.partnerID,
        activeLeader,
        country: user?.userDetails.country
      }, history));
    }
    getMoniteringIDS()
  }, [activeLeader]);

  useEffect(() => {
    const { rep } = store.allRep;
    if (rep.repData) {
      allReps = rep.repData;

      if (rep.leaderIDs) {
        setLeaderIDs(rep.leaderIDs)
      }
      setRep(rep);
      setLoading(false);
    }
  }, [store.allRep, store.allRep.rep, activeLeader]);

  function escapeRegExp(value) {
    value = value.toLowerCase();
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    if (!searchValue || searchValue === '' || searchValue.length === 0) {
      setRep({ ...rep, repData: allReps });
      return;
    }
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = allReps.filter((row) => {
      return SearchRepKeys.some((field) => {
        return searchRegex.test(row[field]?.toString().toLowerCase());
      });
    });
    const newRep = { ...rep, repData: filteredRows };
    setRep(newRep);
  };

  const filterByStatus = (status) => {
    setLoading(true);
    if (status === "ALL") {
      setRep({ ...rep, repData: [...allReps] });
      setLoading(false);
      return;
    }
    const repData = allReps.filter(ele => ele.status === status);
    setRep({ ...rep, repData });
    setLoading(false);
  }

  const onRowClick = (value) => {
    const { type } = value
    if (type === 'Farmer’s List') {
      onClickRow({ ...value, contractorID: value.id })
    }
  }

  return (
    <Grid>
      <Stack direction='row' spacing={2} margin='2rem auto'>
        <TextField
          label='Find Field By ID'
          value={fid}
          sx={{
            width: '30vw'
          }}
          placeholder='Find Field By ID'
          onChange={({ target }) => setFid(target.value)}
        />
        <Button variant='contained' onClick={findField}>
          Find
        </Button>
      </Stack>
      <Stack direction='row' spacing={2} margin='2rem auto'>
        <TextField
          label='Find  By Phone Number'
          value={phoneNumber}
          sx={{
            width: '30vw'
          }}
          placeholder='Find  By Phone Number'
          onChange={({ target }) => setPhoneNumber(target.value)}
        />
        <Button variant='contained' onClick={findUser}>
          Find
        </Button>
      </Stack>
      <h1 style={{
        marginBottom: '2rem',
        justifyContent: 'left',
        paddingLeft: '1.5rem'
      }}>
        Reports
      </h1>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '50%',
          overflow: 'hidden',
          flexDirection: 'row'
        }}
      >
        <Grid item container
          style={{ marginLeft: '1.5rem', marginRight: '1.5rem' }}>
          <Grid item container sx={{ width: '100%', bgcolor: '#F4F6F8', padding: '0.5rem' }} > <strong>REP APP STATUS</strong>
            <Grid direction='column' item container>
              <Grid item>
                <Tabs value={tabs}
                  onChange={(_, v) => {
                    if (loading) {
                      return
                    }
                    filterByStatus(v)
                    setTabs(v)
                  }} >
                  {Object.keys(mapping).map(ele =>
                    <Tab label={`${ele}`} key={ele} value={ele} icon={<Typography bgcolor={color[ele]} padding={0.5} sx={{ borderRadius: 0.5, fontWeight: 600 }} color={ele === 'Read Only' ? 'black' : 'white'} >{(rep?.statusStat && rep?.statusStat[ele]) || 0}</Typography>} iconPosition='start' />
                  )}
                </Tabs>
              </Grid>
              <Grid flex={1} />
              {group.includes('REPSTATUS') && selectedRow.length
                ?
                <Grid item flexDirection="row" display="flex" sx={{ pt: 2 }} >
                  {
                    leaderIDs.length ?
                      <SelectBm
                        size="medium"
                        label="Select leader"
                        value={leaderIdWhiteList}
                        onChange={({ target }) => setLeaderIdWhiteList(target.value)}
                        sx={{
                          bgcolor: '#F1F1F1',
                          borderRadius: 1,
                          width: '7rem'
                        }}
                        list={leaderIDs}
                      /> : null
                  }
                  <SelectBm
                    size="medium"
                    label="Select Status"
                    value={selectStatus}
                    onChange={({ target }) => setSelectStatus(target.value)}
                    sx={{
                      bgcolor: '#F1F1F1',
                      borderRadius: 1,
                      margin: '0 1rem'
                    }}
                    list={["CONFIRMED", "UNCONFIRMED", "REWORK", "READ_ONLY"]}
                    disabled={readonly}
                  />
                  <AutocompleteBm
                    sx={{ width: '50%' }}
                    fullWidth
                    disablePortal={false}
                    chip
                    multiple
                    disableCloseOnSelect
                    label="Monitoring period"
                    list={moniteringPeriodsData?.map(item => `${item.name}(${item.id})`) ?? []}
                    onChange={(_, value) => {
                      let midsArray = []
                      for (const id of value) {
                        midsArray = [...midsArray, id?.split('(')[1].slice(0, -1)]
                      }
                      setMonitneringPeriods((oldValue) => [...oldValue, ...midsArray])
                    }}
                  />
                  <LoadingBtnBm
                    fullWidth={false}
                    onClick={readonly ? null : changeStatusToUnConfirm}
                    loading={statusChangeLoading}
                    variant='contained'
                    sx={{ margin: '0 1rem' }}>
                    Go Ahead
                  </LoadingBtnBm>
                </Grid> : null
              }
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              flexGrow: 1,
              height: '75vh',
              alignSelf: 'flex-end'
            }}
          >
            <DataGrid
              sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                  display: "none"
                }
              }}
              loading={loading && Object.keys(rep).length === 0}
              components={{
                Toolbar: QuickSearchToolbar, NoRowsOverlay: () =>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}>No REP's</Box>
              }}
              rows={rep.repData || []}
              columns={getContratorColumn(onRowClick)}
              pageSize={100}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch('')
                }
              }}
              checkboxSelection={!readonly && group.includes('REPSTATUS')}
              disableSelectionOnClick
              onSelectionModelChange={(newSelection) => {
                const len = selectedRow.length
                if (len > 19 && newSelection.length > len) {
                  setErrorModal(true)
                  return
                }
                SetSelectedRow(newSelection);
              }}
              selectionModel={selectedRow}
            />
          </Grid>
        </Grid>
      </Grid>
      <ModalBM modelUp={errorModal} handelModal={() => setErrorModal(!errorModal)}>
        <ConfirmModal cancel={() => setErrorModal(!errorModal)} />
      </ModalBM>
    </Grid >
  );
};
