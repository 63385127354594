/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Grid, Container, Typography, Stack, CircularProgress, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import GrassIcon from '@mui/icons-material/Grass';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import GrainIcon from '@mui/icons-material/Grain';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import { ranchApi } from '../components/api';
import api from '../components/api/api';
import Page from '../components/Page';
import { CardBM } from '../components/_dashboard/app';
import { ComplexChartBm } from '../components/charts/ComplexChart';
import { MultipleAreaChartBM } from '../components/charts/MultipleAreaChart';
import { PieBM } from '../components/charts/PieChart';
import { BarBm } from '../components/charts/BarBm';
import { TableHeader } from '../components/TableHeaders';
import { getDaysArray } from '../utils/DateModal';
import { userLoginHelper } from '../redux/actions/userAction';
import { AutocompleteBmV2 } from '../components/@material-extend';
import { CustomTooltipBarBm } from '../components/_dashboard/app/CustomTooltipBarBm';
import { CustomTooltipAllDashBoard } from "../components/_dashboard/app/CustomTooltipAllDashBoard"
import setAuthToken from '../redux/helper/setAuthToken';
import setAuthTokenEdit from '../redux/helper/setAuthTokenEdit';

let name = "";
export function AllDashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const store = useSelector((store) => store);
    const [rep, setRep] = useState({});
    const [loading, setLoading] = useState(true);
    const [grasslandData, setGrasslandData] = useState();
    const [combinedData, setCombinedData] = useState();

    const [project, setProject] = useState({
      label: 'ALL',
      id: 'ALL'
    });
    //  GRASSLAND / CROPLAND / ALL;
    const ProjectTypeEnum = {
       GRASSLAND: 'GRASSLAND',
       CROPLAND: 'CROPLAND'
    };
    const [projectType, setProjectType] = useState({
      label: 'ALL',
      id: 'ALL'
    });
    const [allProject, setAllProject] = useState([]);
    const [isGrassland, setIsGrassland] = useState(projectType === ProjectTypeEnum.GRASSLAND);
    const onBarClick = async (row) => {
        const partnerId = row.id
        try {
            if (!partnerId) {
                throw new Error("Invalid")
            }
            const { user } = store.userRoot;
            const { data } = await api.get(`/getPartnerForSwitcher?partnerId=${partnerId}`)
            const [superman, readonly] = [user?.userDetails?.userType === 'ADMIN', user?.userDetails?.userType === 'ADMIN_READ_ONLY']

            if (!data || !data.id) {
                throw new Error("invalid")
            }
            user.userDetails.partnerName = data.name
            let newToken = user.token.split(".");
            newToken = `${newToken[0]}.${newToken[1]}.${newToken[2]}.${data.key}`
            setAuthToken(newToken)
            setAuthTokenEdit(newToken)
            const storeData = {
                group: user.group,
                token: newToken,
                partnerID: data.id,
                userDetails: { ...user?.userDetails, userType: user?.userDetails?.userType },
                partnerDetail: data,
                superman,
                readonly
            };
            localStorage.setItem('JWT', JSON.stringify(storeData));
            dispatch(userLoginHelper(storeData));
            dispatch({ type: "DELETE_HOME_DATA" });
            dispatch({ type: "DELETE_REP_DATA" });
            dispatch({ type: 'DELETE_POLYGON_DATE' })
            navigate('../app');

        } catch (error) {
            alert(error)
        }
    }

  const getCombinedData = async () => {

  const allGrassland = ranchApi
      .get(`home?partnerId=ALL&projectId=ALL`)
      .then(({ data }) =>data)

  const allCropland = api.get(`/completeStatByProject`).then(({ data }) =>data)
   

  Promise.all([allGrassland, allCropland])
    .then(([allGrasslandRes, allCroplandRes]) => {
      setCombinedData({
        producers: +allGrasslandRes?.stat?.totalRancher + +allCroplandRes?.totalFarmer,
        fields: +allGrasslandRes?.stat?.totalRanch + +allCroplandRes?.totalfarms,
        totalArea: +allGrasslandRes?.stat?.totalArea + +allCroplandRes?.totalArea
      });
    })
    .catch((err) => {
      console.log(err);
      alert('failed');
    }).finally(()=>setLoading(false))

  };

  const getGrasslandData= (value)=>{
  setLoading(true)
    ranchApi
      .get(`home?partnerId=ALL&projectId=${value ? value.id : "ALL"}`)
      .then(({ data }) => {
        setGrasslandData({ ...data });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert('failed');
      });
  }
  
    const getCroplandData = async (project) => {
      try {
        if (!project) {
          return;
        }
        setLoading(true);
        let query = '';
        if (project.id !== 'ALL') {
          query = `?type=${project.id}`;
        }
        const { data } = await api.get(`/completeStatByProject${query}`);
        data.getDailyWiseEntry = getDailyWiseEntryWithBuffer(data?.timeSeries);
       
        setRep(data);
      } catch (error) {
        console.log(error);
        alert(error);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
        getProjectList(projectType);
        getCombinedData();
        const { user } = store.userRoot;
        name = user.userDetails.givenName || "";
    }, [store.userRoot]);

    const defaultData = (data) => (
        {
            "TOTALFARM": 0,
            "ONBOARDED": 0,
            "RECEIVED": 0,
            "REWORK": 0,
            "VERIFIED": 0,
            "RE_SUB": 0,
            date: data
        }
    )

    const getDailyWiseEntryWithBuffer = (data) => getDaysArray(data, defaultData, "date")

    const greet = () => {
        const today = new Date()
        const curHr = today.getHours()

        if (curHr < 12) {
            return 'Good morning'
        }
        if (curHr < 18) {
            return 'Good afternoon'
        }
        return 'Good evening'
    }

    const onProjectChange = (_, value) => {
       if (!value) {
         return;
       }
        setProject(value)

        switch(projectType.id){
          case 'ALL': getCombinedData();break;
          case ProjectTypeEnum.GRASSLAND: getGrasslandData(value); break;
          case ProjectTypeEnum.CROPLAND:  getCroplandData(value);break;
          default:getCombinedData();break;
        }
        // if(isGrassland)
        // getGrasslandData(value)  
        // else
        // getCroplandData(value)
 
    }

    const onProjectTypeChange = async (_,value)=>{
       if (!value) {
         return;
       }
        setProjectType(value);
        setIsGrassland(value.id === ProjectTypeEnum.GRASSLAND);
        setProject({
          label: 'ALL',
          id: 'ALL'
        });
        getProjectList(value)
        
          switch (value.id) {
            case 'ALL':
              getCombinedData();
              break;
            case ProjectTypeEnum.GRASSLAND:
              getGrasslandData();
              break;
            case ProjectTypeEnum.CROPLAND:
              getCroplandData({id:"ALL",label:"ALL"});
              break;
            default:
              getCombinedData();
              break;
          }
        // if (value.id === ProjectTypeEnum.GRASSLAND) getGrasslandData();
        // else getCroplandData();

    }

 
    const getProjectList = async (value) => {
      try {
        if (!projectType) return;

        const { data: projectsList } = await api.post(
          `/getProjectsAndPartners?projectType=${value.id}&listName=PROJECT`
        );

        const projects = [];
        projectsList.forEach((val) => {
          projects.push({ label: `${val.id} - ${val.code}`, id: `${val.id}` });
        });
        setAllProject([
          {
            label: 'ALL',
            id: 'ALL'
          },
          ...projects
        ]);
      } catch (err) {
        console.log('Error:', err);
      } finally {
        // setLoading(false)
      }
    };

    return (
      <Page title="Dashboard || REPS">
        <Container maxWidth="xl">
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {greet()}, {name && name[0].toUpperCase() + name.slice(1)}
            </Typography>
            <Typography
              sx={{
                color: 'text.secondary'
              }}
            >
              Here's what's going on with your project at
              <strong>&nbsp; Boomitra</strong>
            </Typography>
          </Stack>
          <Grid item xs={4} md={4}>
            <Box sx={{ display: 'flex', gap: 2, mb: 5 }}>
              <AutocompleteBmV2
                list={[
                  {
                    label: 'ALL',
                    id: 'ALL'
                  },
                  { id: ProjectTypeEnum.GRASSLAND, label: 'Grassland' },
                  { id: ProjectTypeEnum.CROPLAND, label: 'Cropland' }
                ]}
                label="Project Type"
                defaultValue={{ id: ProjectTypeEnum.CROPLAND, label: 'Croplands' }}
                value={projectType}
                valueKey="id"
                onChange={onProjectTypeChange}
              />
              <AutocompleteBmV2
                list={allProject}
                defaultValue={{
                  label: 'ALL',
                  id: 'ALL'
                }}
                label="Project ID"
                value={project}
                valueKey="id"
                disabled={loading || projectType.id === 'ALL'}
                onChange={onProjectChange}
              />
            </Box>
          </Grid>
          {loading && (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <CircularProgress size={100} />
            </Box>
          )}

          {!loading && (
            <Grid container spacing={3}>
              {isGrassland ? (
                <>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                      ICONS={AgricultureIcon}
                      label="NUMBER OF RANCHERS"
                      count={+grasslandData?.stat?.totalRancher}
                      color="linear-gradient(to right, #f8b500, #fceabb)"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                      label="NUMBER OF RANCHES"
                      count={+grasslandData?.stat?.totalRanch}
                      color="linear-gradient(to left, #93F9B9, #1D976C)"
                      ICONS={GrainIcon}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                      ICONS={GrassIcon}
                      label="TOTAL AREA (ACRES)"
                      color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                      count={+grasslandData?.stat?.totalArea}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                      ICONS={GrassIcon}
                      label="TOTAL AREA (HECTARES)"
                      color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                      count={(
                        Number.parseFloat(+grasslandData?.stat?.totalArea) * 0.404686
                      ).toFixed(0)}
                    />
                  </Grid>
                </>
              ) : projectType.id === 'ALL' && combinedData ? (
                <>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                      ICONS={AgricultureIcon}
                      label="NUMBER OF PRODUCERS"
                      count={combinedData?.producers}
                      color="linear-gradient(to right, #f8b500, #fceabb)"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                      label="NUMBER OF FIELDS"
                      count={combinedData.fields}
                      color="linear-gradient(to left, #93F9B9, #1D976C)"
                      ICONS={GrainIcon}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                      ICONS={GrassIcon}
                      label="TOTAL AREA (ACRES)"
                      color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                      count={combinedData?.totalArea}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                      ICONS={GrassIcon}
                      label="TOTAL AREA (HECTARES)"
                      color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                      count={(Number.parseFloat(combinedData?.totalArea) * 0.404686).toFixed(0)}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                      ICONS={CoPresentIcon}
                      label="ACTIVE REPS"
                      count={+rep.totalRep}
                      color="linear-gradient(to right, #4ca1af, #c4e0e5)"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                      ICONS={AgricultureIcon}
                      label="NUMBER OF FARMERS"
                      count={+rep.totalFarmer}
                      color="linear-gradient(to right, #f8b500, #fceabb)"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                      label="NUMBER OF FARMS"
                      count={+rep.totalfarms}
                      color="linear-gradient(to left, #93F9B9, #1D976C)"
                      ICONS={GrainIcon}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                      ICONS={GrassIcon}
                      label="TOTAL AREA (ACRES)"
                      color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                      count={+rep.totalArea}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <CardBM
                      ICONS={GrassIcon}
                      label="ACTIVE PARTNER"
                      color="linear-gradient(315deg, #ffffff 0%, #d8896b 74%)"
                      count={rep.totalPartner}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <TableHeader label="DAILY TRACK FOR LAST 90 DAYS" Icon={AutoGraphIcon} />
                    <ComplexChartBm
                      CustomTooltip={CustomTooltipAllDashBoard}
                      key1="TOTALFARM"
                      key2="ONBOARDED"
                      key3="VERIFIED"
                      key4="RECEVIED"
                      key5="REWORK"
                      key6="RE_SUB"
                      name="date"
                      data={rep.getDailyWiseEntry}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TableHeader label="CUMULATIVE TRACK FOR LAST 90 DAYS" Icon={AutoGraphIcon} />
                    <MultipleAreaChartBM
                      key1="TOTALACRES"
                      key2="ONBOARDED"
                      key3="VERIFIED"
                      key4="RECEVIED"
                      key5="REWORK"
                      key6="RE_SUB"
                      name="date"
                      data={rep.cumlative}
                      ylabel="TOTALACRES"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TableHeader label="FARM STATUS" Icon={DonutLargeIcon} />
                    <PieBM
                      data={[
                        {
                          status: 'ONBOARDED',
                          FARM: +rep.CONFIRMEDFARM
                        },
                        {
                          status: 'VERIFIED',
                          FARM: +rep.VerifiedFarm
                        },
                        {
                          status: 'RECEVIED',
                          FARM: +rep.UNCONFIRMEDFARM
                        },
                        {
                          status: 'REWORK',
                          FARM: +rep.REWORKFARM
                        },
                        {
                          status: 'RE_SUB',
                          FARM: +rep.RE_SUBFarm
                        }
                      ]}
                      nameKey="status"
                      dataKey="FARM"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TableHeader label="AREA STATUS" Icon={DonutLargeIcon} />
                    <PieBM
                      data={[
                        {
                          status: 'ONBOARDED',
                          ACRES: rep.CONFIRMEDAREA
                        },
                        {
                          status: 'VERIFIED',
                          ACRES: rep.VerifiedAREA
                        },
                        {
                          status: 'RECEVIED',
                          ACRES: rep.UNCONFIRMEDAREA
                        },
                        {
                          status: 'REWORK',
                          ACRES: rep.REWORKAREA
                        },
                        {
                          status: 'RE_SUB',
                          ACRES: rep.RE_SUBAREA
                        }
                      ]}
                      nameKey="status"
                      dataKey="ACRES"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TableHeader label="FARM STATUS ON PARTNER" Icon={LeaderboardIcon} />
                    <BarBm
                      CustomTooltip={CustomTooltipBarBm}
                      data={rep.activePartnerStat}
                      x="name"
                      y1="onboarded"
                      y2="verified"
                      y3="received"
                      y4="rework"
                      y5="re_sub"
                      yLabel="total"
                      xaxies={false}
                      barAreaClick={onBarClick}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Container>
      </Page>
    );
}
