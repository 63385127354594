/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-const */
/* eslint-disable import/no-mutable-exports */
import React, { useEffect, useState } from 'react';
import { Paper, Typography, Box, Grid } from '@mui/material';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import { QuickSearchToolbar } from '../QuickSearch';
import { getRancherColumn, SearchRancherKey } from '../GridColumns';
import { RanchdbStats } from './RanchdbStats';


export const RanchTable = ({ranchList, setRanchList, status, allData}) => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  function escapeRegExp(value) {
    value = value.toLowerCase();
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    if (!searchValue || searchValue === '' || searchValue.length === 0) {
      setRanchList([...allData.ranchCompleteDetail]);
      return;
    }
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = ranchList.filter((row) =>
      SearchRancherKey.some((field) => searchRegex.test(row[field]?.toString()?.toLowerCase()))
    );
    if (filteredRows.length === 0) {
      setRanchList([...allData.ranchCompleteDetail]);
    }
    setRanchList(filteredRows);
  };

  const filterDataOnStatus = (status) => {  
    if(!allData.ranchCompleteDetail){
      return 
    } 
    if(status === 'ALL'){
      setRanchList([...allData.ranchCompleteDetail]);
      return;
    }


    const newRanchList = allData.ranchCompleteDetail.filter((ele) => ele.status === status)
    setRanchList([...newRanchList])
  }

  useEffect(()=> {
    filterDataOnStatus(status)
  }, [status])

  const onClickRow = (row) => {
    const url = window.location.pathname;
    const newUrl = url.replace('ranchValidation', 'ranchDetail');
    const rid = row.PK.replace('rancher#', '');
    const id = row.SK.replace('ranch#', '');
    const queryUrl = `?id=${id}&rid=${rid}`;
    window.open(newUrl + queryUrl, '_blank');
  };
  return (
    <Paper
      elevation={10}
      sx={{
        width: '100%',
        marginTop: 5,
        padding: 4
      }}
    >
      <RanchdbStats stat={allData.stat || {}}  />
      <Grid
        container
        direction="column"
        sx={{
          height: '110vh',
          flex: 1
        }}
      >
        <Typography
          variant="h5"
          sx={{
            margin: '1rem'
          }}
        >
          Onboarded Ranches
        </Typography>
        <DataGrid
          components={{
            Toolbar: QuickSearchToolbar,
            NoRowsOverlay: () => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%'
                }}
              >
                No Ranch
              </Box>
            )
          }}
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
              lineHeight: 1
            }
          }}
          rows={ranchList || []}
          loading={loading}
          columns={getRancherColumn(onClickRow)}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch('')
            }
          }}
          disableSelectionOnClick
        />
      </Grid>
    </Paper>
  );
};
